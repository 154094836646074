<template>
    <header id="header">
        <div class="header-top">
            <div class="container">
                <div class="nav-item nav-item-dropdown">
                    <img src="@/assets/icon-common-globe.svg" />
                    <span v-t="'common.language'"></span>
                    <div class="nav-dropdown">
                        <a :class="`nav-link ${locale[0] === $i18n.locale ? 'active' : ''}`"
                            v-for="locale in supportedLocales" :key="locale[0]" @click="switchLocale(locale)">
                            {{ locale[1] }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-main">
            <div class="header-main-wrapper">
                <div class="container">
                    <div class="btn-hamburger" @click="toggleMobileMenu()"></div>
                    <router-link :to="{ name: 'home' }" class="nav-brand">
                        <img class="brand-img" src="@/assets/10-anniv-logo.png" />
                        <!-- <img class="brand-img mobile" src="@/assets/Man_Utd_x_Doo_mobile.png" /> -->
                    </router-link>
                    <router-link :to="{ name: 'home' }" class="nav-brand is-ipad">
                        <img class="brand-img"
                            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-prime-ib/PWEB-56/icon-dp.png" />
                    </router-link>
                    <div :class="`menu-mobile-wrapper ${mobileMenuActive ? 'active' : ''}`">
                        <div class="menu-head">
                            <div class="btn-close" @click="closeMobileMenu()"></div>
                        </div>
                        <div class="menu-url-wrapper-mobile">
                            <router-link :to="{ name: 'home' }" v-t="'header.home'"></router-link>
                            <a v-t="'header.officialWebsite'" class="officialWebsiteURL"></a>
                            <a :href="$t('header.pointsMallUrl')" v-t="'header.pointsMall'"></a>
                            <a :href="$t('header.mediaCenterUrl')" v-t="'header.mediaCenter'"></a>
                            <a :href="$t('header.helpCenterUrl')" v-t="'header.helpCenter'"></a>
                        </div>
                        <div class="nav-language-wrapper">
                            <span class="btn-language-toggle" v-t="'common.language'" @click="setLanguageMenu(true)"></span>
                            <div :class="`nav-language-modal-wrapper ${isLanguageModalOpen === true ? 'active' : ''}`">
                                <div class="overlay" @click="setLanguageMenu(false)"></div>
                                <div class="nav-language-modal">
                                    <h4 class="title" v-t="'common.languageLabel'"></h4>
                                    <div class="nav-dropdown">
                                        <a :class="`nav-link ${locale[0] === $i18n.locale ? 'active' : ''}`"
                                            v-for="locale in supportedLocales" :key="locale[0]"
                                            @click="switchLocale(locale)">
                                            {{ locale[1] }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="menu-url-wrapper">
                        <router-link :to="{ name: 'home' }" v-t="'header.home'"></router-link>
                        <a v-t="'header.officialWebsite'" class="officialWebsiteURL"></a>
                        <a :href="$t('header.pointsMallUrl')" v-t="'header.pointsMall'"></a>
                        <a :href="$t('header.mediaCenterUrl')" v-t="'header.mediaCenter'"></a>
                        <a :href="$t('header.helpCenterUrl')" v-t="'header.helpCenter'"></a>
                    </div>
                    <div class="menu-button-wrapper">
                        <!-- <a v-if="isSA === true" class="btn btn-secondary btn-login" :href="$t('common.loginUrl')"><span v-t="'home.banner.button1'"> </span></a>
                        <a v-if="isSA === false" class="btn btn-primary btn-login" :href="$t('common.loginUrl')"><span v-t="'home.banner.button1'"> </span></a>
                        <a class="btn-login-mobile" :href="$t('common.loginUrl')" v-t="'common.login'"></a>
                        <a v-if="isSA === true" class="btn btn-primary" href="https://partner.dooprimeaffiliates.com/signup/ib" v-t="'home.banner.button2'"></a> -->

                        <!-- <a v-if="this.thisDomain.includes('dooprimepartner')" class="btn btn-secondary btn-login"
                            href="https://ib.dooprimepartner.com/login"><span v-t="'home.banner.button1'"> </span></a>
                        <a v-if="this.thisDomain.includes('dooprimeaffiliates') || this.thisDomain.includes('localhost')"
                            class="btn btn-secondary btn-login" href="https://partner.dooprimeaffiliates.com"><span
                                v-t="'home.banner.button1'"> </span></a> -->
                        <a :href=thisDomain class="btn btn-secondary btn-login"><span v-t="'common.login'">
                            </span></a>
                        <!-- <a v-if="isSA === true" :href=register class="btn btn-primary"><span v-t="'common.register'">
                            </span></a> -->
                        <a class="btn-login-mobile" :href=thisDomain v-t="'common.login'"></a>
                        <!--a v-if="isSA === true && this.thisDomain.includes('dooprimeaffiliates')" class="btn btn-primary" href="https://partner.dooprimeaffiliates.com/signup/ib" v-t="'home.banner.button2'"></a>
                        <a v-if="isSA === true && this.thisDomain.includes('dooprimepartner') || this.thisDomain.includes('localhost')" target="_blank" class="btn btn-primary" href="https://ib.dooprimepartner.com/signup/ib" v-t="'home.banner.button2'"></a-->
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import router from '@/router'

export default {
    name: "Header",
    methods: {
        switchLocale(locale) {
            if (this.$i18n.locale !== locale[0]) {
                const affiliateLang = ['sc', 'tc', 'kr'];
                if (!affiliateLang.includes(locale[0])) {
                    window.location.href = `https://www.dooprime.com/${locale[0]}/partnership/ibs`;
                    return;
                }
                this.setCookie('prefLang', locale[0])
                this.$i18n.locale = locale[0];
                router.push(`/${locale[0]}`);
                window.location.reload();
            }
        },
        toggleMobileMenu() {
            this.mobileMenuActive = !this.mobileMenuActive
        },
        closeMobileMenu() {
            this.mobileMenuActive = false
        },
        setLanguageMenu(check) {
            this.isLanguageModalOpen = check
        },
        stickyNavBar() {
            const HeaderMain = document.querySelector('.header-main-wrapper')
            const sticky = HeaderMain.offsetTop
            if (window.pageYOffset > sticky) {
                HeaderMain.classList.add("sticky");
            } else {
                HeaderMain.classList.remove("sticky");
            }
        },
        setIsShowOurPartnerButton() {
            const domain = window.location.host

            if (domain.match(/affiliate/g) || domain.match(/partner/g)) {
                this.thisDomain = `https://partner.${domain.split('.')[1]}.com`
                this.register = `https://partner.${domain.split('.')[1]}.com/signup/ib` 
            }

            let isSA = this.getCookie('isSA')
            if (isSA) {
                this.isSA = true
            }
        },

        setCookie(name, value) {
            document.cookie = name + "=" + (value || "")
        },
        getCookie(name) {
            let nameEQ = name + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },
        officialWebsiteURL() {
            let officialWebsite = document.querySelectorAll(".officialWebsiteURL");
            let currentCountry = this.getCookie('country');
            let prefLang = this.getCookie('prefLang') || "en";

            for (let i = 0; i < officialWebsite.length; i++) {
                switch (currentCountry) {
                    case "KR":
                        officialWebsite[i].setAttribute("href", "https://www.dooprime.kr");
                        break;
                    case "CN":
                        officialWebsite[i].setAttribute("href", "https://dooprimeint.net");
                        break;
                    case "ID":
                        officialWebsite[i].setAttribute("href", "https://dooprimeapec.com");
                        break;
                    case "AE":
                    case "SA":
                    case "QA":
                    case "KW":
                    case "BH":
                    case "OM":
                    case "EG":
                    case "JO":
                    case "LB":
                    case "IQ":
                    case "PS":
                    case "SY":
                        officialWebsite[i].setAttribute("href", "https://www.dooprime.ar");
                        break;
                    default:
                        officialWebsite[i].setAttribute("href", "https://www.dooprime.com/" + prefLang);
                }
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.stickyNavBar);
        this.setIsShowOurPartnerButton();
        this.officialWebsiteURL();
    },

    data() {
        return {
            supportedLocales: [
                ["en", "English"],
                ["sc", "简体中文"],
                ["tc", "繁體中文"],
                ["kr", "한국어"],
                ["th", "ภาษาอังกฤษ"],
                ["vn", "Tiếng Anh"],
                ["es", "Español"],
                ["pt", "Portuguese"],
                ["ar", "العربية"]
            ],
            mobileMenuActive: false,
            isLanguageModalOpen: false,
            isSA: false,
            thisDomain: "",
            register: ""
        }
    },
}
</script>

<style lang="scss">
#header {
    z-index: 1000;
    position: relative;

    .sticky {
        position: fixed !important;
        top: 0 !important;
        width: 100% !important;
        background-color: #ffffff;


    }



    .header-top {
        background-color: #000018;

        @include mobile-screen() {
            display: none;
        }

        .container {
            display: flex;

            .nav-item {
                margin-left: auto;
                color: #ffffff;
                position: relative;
                padding: 7px 0 7px;
                display: flex;
                align-items: center;
                font-size: 16px;

                // &::before {
                //     content: "";
                //     width: 1px;
                //     height: 12px;
                //     background-color: #ffffff;
                //     position:absolute;
                //     left: -24px;
                //     top: 50%;
                //     transform: translateY(-50%);
                // }
                span {
                    position: relative;
                    display: flex;
                    align-items: center;

                    &::after {
                        content: "";
                        display: block;
                        width: 0;
                        height: 0;
                        border-top: 6px solid #ffffff;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        margin-left: 6px;
                    }
                }

                img {
                    width: 20px;
                    margin-right: 8px;
                }

                a {
                    color: #ffffff;
                }

                .nav-dropdown {
                    z-index: 100;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    padding: 20px;
                    background-color: #ffffff;
                    display: none;

                    a {
                        padding: 4px 0 6px;
                        color: #000018;
                        white-space: nowrap;
                        display: block;
                        position: relative;
                        cursor: pointer;

                        &:hover {
                            color: #d91d22
                        }

                        &.active {
                            color: #d91d22
                        }
                    }
                }

                &:hover {
                    .nav-dropdown {
                        display: block;
                    }
                }
            }
        }
    }

    .header-main {
        position: relative;

        .btn-hamburger {
            content: url(@/assets/icon-common-hamburger.svg);
            margin-right: 11px;
            display: none;

            @include mobile-screen () {
                display: block;
            }
        }

        .nav-brand {
            margin-right: 60px;
            align-self: center;

            @include mobile-screen() {
                margin-right: 0;
            }

            @include ipad-screen() {
                display: none;
            }

            .brand-img {
                width: 222.9px;
                &.mobile {
                    display:none;
                }

                @include mobile-screen() {
                    &.desktop {
                        display:none;
                    }
                    &.mobile {
                        display: inline-block;
                    }
                    width: 131px;
                }
            }
        }

        .is-ipad {
            display: none;

            @media screen and (min-width: 1025px) and (max-width: 1200px) {
                display: block;

                .brand-img {
                    width: 40px;
                }
            }
        }


        .header-main-wrapper {
            padding: 22px 16px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;






            .menu-mobile-wrapper {
                display: none;

                @include mobile-screen() {
                    display: block;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1001;
                    background-color: #ffffff;
                    transform: translateX(-100%);
                    transition: 0.8s;

                    &.active {
                        transform: translateX(0);
                        transition: 0.8s;

                    }

                    .btn-close {
                        content: url(@/assets/icon-common-close.svg);
                        margin-right: 11px;
                        margin-top: 28px;
                        margin-left: 16px;
                    }

                    .menu-url-wrapper-mobile {
                        padding: 57px 25px 64px 26px;

                        a {
                            color: #000018;
                            display: block;
                            padding: 16px 0;
                            border-bottom: 1px solid #e4e4e4;

                            &.router-link-active {
                                color: #d91d22
                            }
                        }
                    }

                    .nav-language-wrapper {
                        position: relative;
                        text-align: center;

                        .btn-language-toggle {
                            text-align: center;
                            cursor: pointer;
                            position: relative;

                            &::after {
                                content: "";
                                position: absolute;
                                top: 42%;
                                right: calc(-12px - 15px);
                                width: 0;
                                height: 0;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                border-top: 6px solid #000018;
                            }
                        }

                        .nav-language-modal-wrapper {
                            .overlay {
                                position: fixed;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                background-color: rgba(0, 0, 0, 0.5);
                                opacity: 0;
                                pointer-events: none;
                                transition: 0.8s;
                            }

                            .nav-language-modal {
                                width: 100%;
                                max-width: 265px;
                                opacity: 0;
                                pointer-events: none;
                                position: absolute;
                                background-color: #ffffff;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -30%);
                                padding: 25px 32px 43px;
                                transition: 0.8s;

                                .title {
                                    margin-bottom: 29px;
                                    text-align: center;
                                }

                                .nav-dropdown {
                                    text-align: left;

                                    a {
                                        display: block;
                                        font-size: 16px;
                                        font-weight: normal;
                                        line-height: 1.5;
                                        letter-spacing: 1.5px;
                                        color: #000018;

                                        &:not(:last-child) {
                                            margin-bottom: 22px;
                                        }

                                        &.active {
                                            color: #d91d22
                                        }
                                    }
                                }
                            }

                            &.active {
                                .overlay {
                                    opacity: 1;
                                    transition: 0, 8s;
                                    pointer-events: all;
                                }

                                .nav-language-modal {
                                    opacity: 1;
                                    transform: translate(-50%, -50%);
                                    transition: 0.8s;
                                    pointer-events: all;
                                }
                            }
                        }
                    }
                }
            }

            @include mobile-screen() {
                padding: 28px 0;
            }

            .container {
                display: flex;

                .menu-url-wrapper {
                    display: flex;
                    align-items: center;

                    @media screen and (max-width: 1024px) {
                        display: none;
                    }

                    a {
                        margin-right: 64px;
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 1.5;
                        letter-spacing: 1.5px;
                        color: #000018;
                        position: relative;

                        &:first-child::after {
                            content: "";
                            position: absolute;
                            bottom: -8px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 30px;
                            height: 2px;
                            background-color: #d91d22;
                        }

                        &:hover {
                            &::after {
                                content: "";
                                position: absolute;
                                bottom: -8px;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 30px;
                                height: 2px;
                                background-color: #d91d22;
                            }
                        }
                    }
                }

                .menu-button-wrapper {
                    margin-left: auto;
                    display: flex;

                    a {
                        margin-left: 24px;

                        @include mobile-screen() {
                            margin-left: 16px;
                            padding: 5px 8px 7px;

                            &.btn-primary {
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 1.71;
                                letter-spacing: 1.5px;
                            }
                        }
                    }

                    .btn-primary {
                        display: flex;
                        min-width: 150px;
                        justify-content: center;
                        align-items: center;

                        @include mobile-screen() {
                            min-width: unset;
                            display: none;
                        }
                    }

                    .btn-login {
                        display: flex;
                        min-width: 150px;
                        justify-content: center;
                        align-items: center;

                        @include mobile-screen() {
                            display: none;
                        }
                    }

                    .btn-login-mobile {
                        display: none;
                        align-self: center;

                        @include mobile-screen() {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
</style>
