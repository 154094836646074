<template>
    <section class="banner">
        <div class="container">
            <div class="content-wrapper">
                <h1 :class="$i18n.locale" class="title" v-t="'home.banner.title'"></h1>
                <h1 class="description" v-t="'home.banner.desc'"></h1>
                <div class="btn-wrapper">
                    <a class="btn btn-primary" :href="thisDomain" target="_blank"><span v-t="'common.login'"> </span></a>
                    <!-- <a v-if="isSA === true" :href=register class="btn btn-secondary affiliates" target="_blank"><span v-t="'common.register'">
                            </span></a> -->
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    methods: {
        setIsShowOurPartnerButton() {
            const domain = window.location.host

            if (domain.match(/affiliate/g) || domain.match(/partner/g)) {
                this.thisDomain = `https://partner.${domain.split('.')[1]}.com/${this.$i18n.locale}`
                this.register = `https://partner.${domain.split('.')[1]}.com/signup/ib` 
            }


            let isSA = this.getCookie('isSA')
            if (isSA) {
                this.isSA = true
            }
        },
        getCookie(name) {
            let nameEQ = name + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        }
    },
    mounted() {
        this.setIsShowOurPartnerButton()
    },
    data() {
        return {
            isSA: false,
            domainRegisterUrl: '',
            domain: '',
            thisDomain: '',
            register:''
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto');

#page-home {
    .banner {
        padding-top: 259px;
        height: 820px;
        background-image: url('@/assets/pages/home/banner_ibparnter_PC_2560.jpg');
        background-position: center;
        background-size: cover;
        position: relative;

        @media screen and (max-width: 1024px) {
            background-image: url('@/assets/pages/home/banner_ibparnter_Mobile_1024.jpg');
            padding-top: 104px;
            height: 675px;
            text-align: center;
        }

        .content-wrapper {
            margin-left: 123px;

            @include mobile-screen() {
                margin-left: 0;
            }

            .title {
                font-size: 64px;
                font-weight: normal;
                line-height: normal;
                letter-spacing: 1px;
                color: #000018;
                position: relative;

                @include mobile-screen() {
                    font-size: 28px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -27px;
                    left: 0;
                    width: 34px;
                    height: 2px;
                    background-color: #d91d22;

                    @include mobile-screen() {
                        display: none;
                    }
                }
            }

            .vn {
                font-family: Roboto;

            }

            .description {
                max-width: 387px;
                margin-top: 62px;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.5;
                letter-spacing: 0.25px;
                color: #36383b;

                @include mobile-screen() {
                    margin-top: 24px;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 387px;
                }

                @media screen and (min-width: 1025px) and (max-width: 1111px) {
                    width: 15.5vw;
                    max-width: 387px;
                }

                @media screen and (min-width: 1111px) and (max-width: 1440px) {
                    width: 19.5vw;
                    max-width: 387px;
                }

                @media screen and (min-width: 1440px) and (max-width: 2250px) {
                    max-width: 387px;
                }
            }

            .btn-wrapper {
                display: flex;
                margin-top: 129px;

                .btn {
                    min-width: 150px;

                    &:not(:last-child) {
                        margin-right: 24px;

                        @include mobile-screen() {
                            margin-right: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                @include mobile-screen() {
                    position: absolute;
                    bottom: 40px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}
</style>
